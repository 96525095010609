<template>
  <v-dialog :value="dialog" persistent max-width="1200" @click:outside="$emit('close')" >
    <v-card>
      <v-card-title class="text-h5">
        {{ this?.currentSession?.workspace?.name || this.chatWorkspace?.name ? this.currentSession?.workspace?.name || this.chatWorkspace?.name : 'Your' }} Settings
      </v-card-title>
      <v-row class="AIChatSettings__header">
        <h4>Chat Model</h4>
      </v-row>
      <v-row class="AIChatSettings__toolSet_dropdown">
        <v-col>
          <v-autocomplete
              class="mb-3"
              style="width: 250px"
              outlined
              dense
              label="AI Model"
              :items="models"
              v-model="selectedModel"
              item-text="label"
              item-value="value"
              :disabled="disableSettings()"
              @change="(value) => updateSettings({ model: value })"
          ></v-autocomplete>
        </v-col>
      </v-row>
     <v-row class="AIChatSettings__header">
       <h4>Tool Set: <span v-if="disableSettings()"> {{ this.selectedToolSetEntity?.name }}</span></h4>
     </v-row>
      <v-row class="AIChatSettings__toolSet_dropdown" v-if="!disableSettings()">
        <v-col>
          <v-autocomplete
              class="mb-3"
              outlined
              dense
              label="Tool Set"
              :items="toolSets"
              v-model="selectedToolSet"
              item-text="name"
              item-value="id"
              @change="(value) => selectToolSet(value)"
              :append-outer-icon="toolSetsLoading ? 'mdi-loading mdi-spin' : 'mdi-reload'"
              @click:append-outer="this.getToolSets"
              clearable
          ></v-autocomplete>
        </v-col>
        <v-col>
          <v-btn  v-if="!disableSettings()" @click.stop="isCreateToolSetOpen = true" class="AIChatSettings__sendButton">
            Create New
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="selectedToolSetEntity" class="ml-8">
        <v-col>
          <v-row class="mb-3 align-center">
            <h4>Tools</h4>
            <v-btn style="" v-if="!disableSettings()" icon @click.stop="setEditToolSet({...selectedToolSet}); isEditToolSetOpen = true">
              <v-icon small> mdi-pencil-outline </v-icon>
            </v-btn>
          </v-row>
          <li v-for="tool in selectedToolSetEntity.tools" :key="tool.id" class="AIChatSettings__tools_list">
            <lu>
              {{ tool?.name }};
            </lu>
          </li>
        </v-col>
      </v-row>
      <v-row class="AIChatSettings__header">
          <h4>System Prompt</h4>
      </v-row>
      <v-row class="AIChatSettings__systemPrompt">
        <v-col>
          <v-textarea
              class="TextArea"
              outlined
              dense
              :placeholder="'System Prompt'"
              hide-details="auto"
              novalidate
              :rows="5"
              :disabled="disableSettings()"
              v-model="systemPromptText"
          >
          </v-textarea>
        </v-col>
        <v-col>
          <v-row>
          <v-autocomplete
              class="mb-3 mt-3"
              outlined
              dense
              clearable
              label="System Prompts"
              :items="systemPrompts"
              v-model="selectedSystemPrompt"
              item-text="name"
              item-value="id"
              :disabled="disableSettings()"
              @change="(value) => updateSelectedSystemPrompt(value)"
              :append-outer-icon="systemPromptLoading ? 'mdi-loading mdi-spin' : 'mdi-reload'"
              @click:append-outer="this.getSystemPrompts"
          ></v-autocomplete>
          </v-row>
          <v-row>
            <v-btn :disabled="disableSettings()" @click.stop="saveSystemPrompt()" class="AIChatSettings__sendButton" :loading="updateSystemPromptLoading">
              Save
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-row  class="AIChatSettings__header">
        <h4>Max Tokens</h4>
      </v-row>
      <v-row class="AIChatSettings__systemPrompt">
        <div class="maxTokens">
          <v-slider
              v-on:input="debounceMaxTokens"
              v-model="maxTokens"
              :min="1000"
              :max="15000"
              style="width: 100%;"
              :disabled="disableSettings()"
          ></v-slider>
          <span>
             {{ maxTokens }}
          </span>
        </div>
      </v-row>
      <v-row  class="AIChatSettings__header">
        <h4>Number of latest included messages</h4>
      </v-row>
      <v-row class="AIChatSettings__historyLimit">
        <div class="maxTokens">
          <v-slider
              v-on:input="debounceHistoryLimit"
              v-model="historyLimit"
              :min="0"
              :max="50"
              :disabled="disableSettings() || this.includeAllHistory"
          ></v-slider>
          <span>
            {{ historyLimit }}
          </span>
        </div>
        <v-checkbox v-if="isShAdmin()" label="Include All" v-model="includeAllHistory" @change="updateSettings({ historyLimit: includeAllHistory ? null : historyLimit })" :disabled="disableSettings()">
        </v-checkbox>
      </v-row>
      <v-row class="AIChatSettings__header">
        <h4>Additional</h4>
      </v-row>
      <v-row class="AIChatSettings__systemPrompt">
        <v-checkbox
            class="mb-3 mt-3"
            type="checkbox"
            id="checkbox"
            v-model="hideToolIO"
            label="Hide Tool Input/Output"
            @change="updateAdditional()"
        ></v-checkbox>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')"> Close </v-btn>
      </v-card-actions>
    </v-card>
    <create-tool-set :dialog="isCreateToolSetOpen" @close="isCreateToolSetOpen = false"></create-tool-set>
    <tools-select :dialog="isEditToolSetOpen" :toolSets="selectedToolSetEntity?.tools" @close="isEditToolSetOpen = false" @toolsChanged="updateToolSetData"/>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import CreateToolSet from "@/components/modals/tool-set/create-tool-set/create-tool-set.vue";
import debounce from "lodash/debounce";
import lodashGet from "lodash/get";
import ToolsSelect from "@/views/ai-workspaces/create-workspace/tools-select/tools-select.vue";
const {
  mapGetters: AIChatGetters,
  mapActions: AIChatActions,
  mapMutations: AIChatMutations,
} = createNamespacedHelpers('AIChat');

const { mapActions: workspaceActions } = createNamespacedHelpers('workspace');

const { mapGetters: customerGetters } =
    createNamespacedHelpers('customer');

export default {
  name: 'AIChatSettings',
  components: { ToolsSelect, CreateToolSet },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCreateToolSetOpen: false,
      isEditToolSetOpen: false,
      systemPromptText: '',
      selectedToolSet: null,
      selectedToolSetEntity: null,
      selectedSystemPrompt: null,
      hideToolIO: null,
      maxTokens: 2000,
      historyLimit: 15,
      includeAllHistory: false,
      models: [
        {
          label: 'Claude V3 Sonnet',
          value: 'anthropic.claude-3-sonnet-20240229-v1:0'
        },
        {
          label: 'Claude V3.5 Sonnet',
          value: 'anthropic.claude-3-5-sonnet-20240620-v1:0'
        },
        {
          label: 'Claude V3 Haiku',
          value: 'anthropic.claude-3-haiku-20240307-v1:0'
        },
        {
          label: 'Claude V2',
          value: 'anthropic.claude-v2:1'
        }
      ],
      selectedModel: {
        label: 'Claude V3 Haiku',
        value: 'anthropic.claude-3-haiku-20240307-v1:0'
      },
    };
  },
  computed: {
    ...AIChatGetters({
      toolSets: 'TOOL_SETS',
      toolSetsLoading: 'TOOL_SETS_LOADING',
      userSettings: 'USER_SETTINGS',
      systemPrompts: 'SYSTEM_PROMPTS',
      systemPromptLoading: 'SYSTEM_PROMPT_LOADING',
      updateSystemPromptLoading: 'UPDATE_SYSTEM_PROMPT_LOADING',
      currentToolSet: 'CURRENT_TOOL_SETS',
      currentSession: 'CURRENT_SESSION',
      chatWorkspace: 'GET_CHAT_WORKSPACE',
    }),
    ...customerGetters({
      customer: 'CUSTOMER',
      user: 'USER',
      customers: 'CUSTOMERS',
      selected_customer: 'SELECTED_CUSTOMER',
      featureFlags: 'FEATURE_FLAGS',
    }),
  },
  watch: {
    currentSession: {
      immediate: true,
      async handler(newVal, oldVal) {
        if(newVal?.workspaceId === oldVal?.workspaceId) {
          return;
        }
        if(!newVal.workspaceId && !this.chatWorkspace?.id) {
          await this.setSettingsFromUser()
          return;
        }
        await this.setSettingsFromWorkspace(newVal?.workspaceId || this.chatWorkspace?.id)
      }
    },
    chatWorkspace: {
      immediate: true,
      async handler(newVal, oldVal) {
        if(newVal?.id === oldVal?.id) {
          return;
        }
        if(!newVal?.id) {
          await this.setSettingsFromUser()
          return;
        }
        await this.setSettingsFromWorkspace(newVal?.workspaceId || this.chatWorkspace?.id)
      }
    }
  },
  methods: {
    ...AIChatActions(['updateUserSettings', 'getToolSets', 'getSystemPrompts', 'updateSystemPrompt', 'createSystemPrompt', 'updateToolSet']),
    ...AIChatMutations({
      setSystemPrompts: 'SET_SYSTEM_PROMPTS',
      setEditToolSet: 'SET_EDIT_TOOL_SET',
    }),
    ...workspaceActions(['getWorkspace']),
    disableSettings() {
      return !!this.currentSession.workspaceId || !!this.chatWorkspace?.id;
    },
    async updateToolSetData(data) {
      if(!this.selectedToolSetEntity?.id) {
        return;
      }
      this.selectedToolSetEntity.tools = data;
      await this.updateToolSet({
        toolSetIds: data.map((tool) => tool.id),
        id: this.selectedToolSetEntity.id,
      })
    },
    isShAdmin() {
      return lodashGet(this.customer, 'customer_id') === 0;
    },
    async updateSettings(data) {
      if(this.disableSettings()) {
        return;
      }
      await this.updateUserSettings(data)
    },
    async selectToolSet(value) {
      this.selectedToolSetEntity = this.toolSets.find((s) => s.id === value);
      if(this.selectedToolSetEntity) {
        await this.updateSettings({ toolSetId: value });
      }
    },
    async saveSystemPrompt() {
      if(this.selectedSystemPrompt?.id) {
        await this.updateSystemPrompt({
          systemPromptId: this.selectedSystemPrompt.id,
          prompt: this.systemPromptText,
        });
        return;
      }
      const prompt = await this.createSystemPrompt({
        prompt: this.systemPromptText,
        name: this.systemPromptText,
      })
      this.systemPrompts.push(prompt);
      this.selectedSystemPrompt = prompt;
      this.systemPromptText = prompt.prompt;
      await this.updateSettings({ systemPromptId: prompt.id });
    },
    async updateSelectedSystemPrompt(value) {
      const systemPrompt = this.systemPrompts.find((prompt) => prompt.id === value);
      await this.updateSettings({ systemPromptId: systemPrompt.id });
      this.systemPromptText = systemPrompt.prompt;
      this.selectedSystemPrompt = systemPrompt;
    },
    async updateAdditional() {
      await this.updateSettings({ hideToolIO: this.hideToolIO });
    },
    setSettingsFromUser() {
      this.selectedToolSet = this.toolSets.find((set) => set.id === this.userSettings.toolSetId);
      this.selectedToolSetEntity = this.toolSets.find((set) => set.id === this.userSettings.toolSetId);
      const systemPrompt = this.systemPrompts.find((prompt) => prompt.id === this.userSettings.systemPromptId);
      this.selectedSystemPrompt = systemPrompt;
      this.systemPromptText = systemPrompt?.prompt;
      this.hideToolIO = this.userSettings.hideToolIO;
      this.selectedModel = this.models.find((model) => model.value === this.userSettings.model)
      this.maxTokens = this.userSettings.maxTokens
      this.historyLimit = this.userSettings.historyLimit;
      this.includeAllHistory = this.userSettings.historyLimit === null || this.userSettings.historyLimit === undefined;
    },
    async setSettingsFromWorkspace(workspaceId) {
      const workspace = await this.getWorkspace({workspaceId});
      this.selectedToolSet = {
        name: workspace?.name,
        tools: workspace?.tools,
      };
      this.selectedToolSetEntity = {
        name: workspace?.name,
        tools: workspace?.tools,
      };
      const systemPrompt = workspace.SystemPrompt;
      this.selectedSystemPrompt = systemPrompt;
      this.systemPromptText = systemPrompt?.prompt;
      this.hideToolIO = this.userSettings.hideToolIO;
      this.selectedModel = this.models.find((model) => model.value === workspace.model)
      this.maxTokens = workspace.maxTokens;
      this.historyLimit = workspace.historyLimit;
      this.includeAllHistory = workspace.historyLimit === null || workspace.historyLimit === undefined;
    },
    debounceMaxTokens: debounce(function (e) {
      this.maxTokens = e;
      this.updateSettings({ maxTokens: this.maxTokens });
    }, 1000),
    debounceHistoryLimit: debounce(function (e) {
      this.historyLimit = e;
      this.updateSettings({ historyLimit: this.historyLimit });
    }, 1000)
  },
  async created() {
    await this.getSystemPrompts();
    await this.getToolSets();
    if(this.currentSession?.workspaceId) {
      await this.setSettingsFromWorkspace(this.currentSession?.workspaceId);
    } else if(this.chatWorkspace?.id) {
      await this.setSettingsFromWorkspace(this.chatWorkspace?.id);
    } else {
      this.setSettingsFromUser()
    }
  }
};
</script>

<style lang="scss" scoped>
@import './ai-chat-settings';
</style>
